<template>
  <div class="notification-detail common-page">
    <Spin size="large" fix v-if="searchLoading"></Spin>
    <div v-if="componentName == 'default'">
      <header class="page-header">
        <div @click="goBack" class="header-left">
          <Icon class="back-icon" type="md-arrow-round-back" />
          <span class="back-title">返回通报概况</span>
        </div>
        <div class="header-title">通报详情</div>
      </header>
      <section class="page-content">
        <div class="content-header">
          <h1 class="content-title">{{targetRow.title}}</h1>
          <h2 class="content-sub-title">通报周期：  {{targetRow.noticePeriod}}</h2>
        </div>
        <div class="govern-content">
          <div class="govern-notice">
            <div v-for="item in noticeData" :key="item.key" class="notice-item">
              <span class="notice-label">{{item.title}}:</span>
              <span class="notice-value">{{item.data}}</span>
            </div>
            <div v-if="isNeedUpload" class="notice-item flex-item">
              <span class="notice-label">验收申请附件:</span>
                <Upload
                  v-if="targetRow.governSchedule == 3 && btnPrivilegeCode.notification.includes('Upload')"
                  ref="upload"
                  :action="uploadUrl"
                  :headers="uploadHeader"
                  multiple
                  :before-upload="beforeFileUpload"
                  :on-success="fileUploadFinish"
                  :on-error="fileUploadFinish">
                  <span class="btn-span">选择文件</span>
                </Upload>
                <span v-else-if="targetRow.governSchedule ==  4">
                  <a v-for="fileItem in fileList" class="btn-span" :href="fileItem.url" :key="fileItem.id">{{fileItem.fileName}}</a>
                </span>
                <span v-else class="notice-value">待上传</span>
            </div>
            <div v-if="isNeedUpload" class="notice-item">
              <Icon type="md-alert" class="notice-icon" />
              <span class="notice-info">
                验收申请附件，根据有关行政要求，必须上传七个附件，七个附件依次为：（1）县运管下发的整改通知书；（2）企业整改方案及措施；（3）企业对驾驶员的处理意见；（4）车辆封存停运图片；（5）驾驶员接受安全教育的凭证；（6）企业向运管所提出的整改报告；（7）县运管的复查验收意见</span>
            </div>
            <div v-if="isNeedUpload" class="notice-item">
              <span class="notice-label"></span>
              <Button v-if="targetRow.governSchedule ==  3 && btnPrivilegeCode.notification.includes('Upload')" @click="saveUploadFiles" :loading="uploadLoading" type="primary" >保存附件信息</Button>
            </div>
          </div>
          <div class="detail-item">
            <header class="item-title">通报附件</header>
            <div class="item-table">
              <Table :columns="attachmentColumns" :data="attachmentData" size="small" max-height="300">
                <template slot-scope="{ row }" slot="action">
                  <a :href="row.url" class="btn-span">下载附件</a>
                  <!-- <span class="btn-span">在线预览</span> -->
                </template>
              </Table>
            </div>
          </div>
          <div class="detail-item">
            <header class="item-title">通报违章车辆</header>
            <div class="item-table">
              <Table :columns="breakRuleVehColumns" :data="alarmDataList" size="small" max-height="300">
                <template slot-scope="{ row }" slot="vehicle">
                  {{`${row.vehicleNo}(${row.vehiclePlateColor})`}}
                </template>
                <template slot-scope="{ row }" slot="action">
                  <span v-if="btnPrivilegeCode.illegal.includes('Details')" @click="openGovernDetail(row)" class="btn-span">查看</span>
                  <span v-if="filterAction(row) && btnPrivilegeCode.governance.includes('Govern')" @click="governVehicle(row)" class="btn-span">治理</span>
                </template>
              </Table>
              <div class="pagenation-wrapper">
                <Page
                  :current="paginationConfig.currentPage"
                  :page-size="paginationConfig.pageSize"
                  :total="paginationConfig.total"
                  @on-change="currentPageChange"
                  show-total
                  show-elevator
                  size="small"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- 违章详情 -->
    <div v-else-if="componentName == 'IllegalDetail'">
      <IllegalDetail :params="{targetRow: clickRow, backTitle: '通报详情', backComponentName: 'default'}" @goBack="changeComponentName"></IllegalDetail>
    </div>
    <!-- 编辑治理单 -->
    <div v-else-if="componentName == 'GovernEdit'">
      <GovernEdit v-if="clickRow && componentName == 'GovernEdit'" :params="{governId: clickRow.governId, backTitle: '通报详情', backComponentName: 'default'}"  @goBack="changeComponentName" @reSetGoverns="reSetGoverns"></GovernEdit>
    </div>
    <!-- 添加治理单 -->
    <div v-else-if="componentName == 'IllegalCreate'">
      <IllegalCreate v-if="componentName == 'IllegalCreate'" :params="{...targetGovernParams, backTitle: '通报详情', backComponentName: 'default'}" :isShowPrevStepBtn="false" @goBack="changeComponentName"></IllegalCreate>
    </div>
  </div>
</template>
<script>
import {getToken} from '@/assets/js/sessionStorage.js'
import axios from 'axios'
import {btnPrivilegeMixin, paginationMixin, alarmTableMixin, privilegeMixin, userInfoMixin} from '@/assets/js/mixin.js'
import IllegalCreate from '../../illegal/illegal_create/index.vue'
import IllegalDetail from '../../illegal/illegal_detail/index.vue'
import GovernEdit from '../../governance/govern_edit/index.vue'
export default {
  mixins: [btnPrivilegeMixin, paginationMixin, alarmTableMixin, privilegeMixin, userInfoMixin],
  props: {
    targetRow: Object
  },
  components: {
    IllegalCreate,
    GovernEdit,
    IllegalDetail
  },
  data () {
    return {
      componentName: 'default',
      searchLoading: false,
      noticeData: [
        {title: '所属企业', key: 'unitName', data: ''},
        {title: '通报下发时间', key: 'createTime', data: ''},
        // {title: '截止处理时间', key: 'noticeDeadline', data: ''},
        // {title: '剩余治理时间(天)', key: 'governDay', data: ''},
        // {title: '剩余治理车辆(辆)', key: 'needHandleNum', data: ''},
        {title: '治理进度', key: 'governScheduleRe', data: ''}
      ],
      // 申请附件列表
      attachmentFileList: [],
      uploadLoading: false,
      // 通报附件
      attachmentColumns: [
        {title: '附件名称', key: 'fileName', align: 'center'},
        {title: '操作', slot: 'action', align: 'center'}
      ],
      fileList: [], // 附件
      attachmentData: [],
      // 违章车辆
      breakRuleVehColumns: [
        {type: 'index', width: 60, align: 'center'},
        {title: '违章车辆', slot: 'vehicle', align: 'center'},
        {title: '违章类型', key: 'alarmTypeRe', align: 'center'},
        {title: '治理单', key: 'governId', align: 'center'},
        {title: '治理单状态', key: 'governStatusRe', align: 'center'},
        {title: '操作', slot: 'action', align: 'center'}
      ],
      // 违章车辆
      alarmDataList: [],
      breakRuleVehData: [],
      clickRow: null,
      targetGovernParams: {
        noticeId: '',
        beginTime: '',
        endTime: '',
        step: 1,
        governWay: 2,
        vehicle: ''
      },
      noticeBeginDate: '',
      noticeEndDate: '',
      isNeedUpload: false // 是否需要上传附件
    }
  },
  computed: {
    uploadUrl() {
      return `${WEB_CONFIG.BASE_IP}/govern-web/file/uploadToObs`
    },
    uploadHeader() {
      return {
        Authentication: getToken()
      }
    }
  },
  created () {
    this.getTotalData()
  },
  methods: {
    // 获取详情数据
    async getTotalData () {
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'get',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/notice/noticeDetail?noticeId=${this.targetRow.noticeId}`,
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.isNeedUpload = data.data.needUpload == 1 ? true : false
          this.noticeBeginDate = data.data.noticeBeginDate
          this.noticeEndDate = data.data.noticeEndDate
          this.initData(data.data)
          this.breakRuleVehData = data.data.alarmDataList
          this.attachmentData = data.data.noticeSendFileList
          this.fileList = data.data.noticeUploadFileList
          this.paginationConfig.currentPage = 1
          this.paginationConfig.total = data.data.alarmDataList.length
          this.setAlarmDataList()
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取通报详情失败, 请稍后重试!')
        }
      }
    },
    // 设置违章车辆数据
    setAlarmDataList() {
      this.alarmDataList = this.breakRuleVehData.filter((item, index) => {
        return (index >= (this.paginationConfig.currentPage - 1) * this.paginationConfig.pageSize && index < this.paginationConfig.currentPage * this.paginationConfig.pageSize)
      })
    },
    // 翻页改变
    currentPageChange (currentPage) {
      this.paginationConfig.currentPage = currentPage
      this.setAlarmDataList()
    },
    initData (data) {
      this.noticeData.forEach(item => {
        for(let [key, value] of Object.entries(data)) {
          if (item.key == key) {
            item.data = value
          }
        }
      })
    },
    // 根据条件展示操作btn
    filterAction (row) {
      let result = false
      if (!row.governNo || row.governStatus <= 0||(row.governNo==1&&(row.governStatus == 4||row.governStatus == 3))) {
        // 治理单不存在 则显示治理
        result = true
      }
      if (row.governNo == 2) {
        // 治理
        // 治理中 治理待审核 治理未提交 治理未通过 都显示治理按钮
        if (row.governStatus == 3 || row.governStatus == 4 || row.governStatus == 5 || row.governStatus == 7) {
          result = true
        }
      }
      return result
    },

    beforeFileUpload () {
      this.uploadLoading = true
    },
    fileUploadFinish () {
      this.uploadLoading = false
    },
    // 保存附件信息
    async saveUploadFiles () {
      let _fileList = this.$refs.upload.fileList.map(item => {
        return {
          fileName: item.response.data.fileName,
          url: item.response.data.fileURL,
        }
      })
      if (_fileList.length == 0) {
        this.$Message.warning('请先选择申请附件')
        return
      }
      let _params = {
        appKey: this.userInfo.appKey,
        noticeId: this.targetRow.noticeId,
        fileList: _fileList
      }
      this.uploadLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/notice/checkNotice`,
          data: _params
        })
        this.uploadLoading = false
        if (data.code === 200) {
          this.$Message.success('保存成功')
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.uploadLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('保存文件失败, 请稍后重试!')
        }
      }
    },
    // 查看详情
    openGovernDetail (row) {
      row['noticeId'] = this.targetRow.noticeId
      row['beginTime'] = this.noticeBeginDate
      row['endTime'] = this.noticeEndDate
      this.clickRow = row
      this.componentName = 'IllegalDetail'
    },
    // 治理
    governVehicle (row) {
      this.clickRow = row
      this.targetGovernParams.noticeId = this.targetRow.noticeId
      if (row.governStatus <= 0 || row.governNo <= 0||(row.governNo==1&&row.governStatus==3)) {
        // 治理单未生成 则跳转到违章生成页面
        this.targetGovernParams.step = 1
        this.targetGovernParams.beginTime = this.noticeBeginDate
        this.targetGovernParams.endTime = this.noticeEndDate
        this.targetGovernParams.vehicle = `${row.vehicleNo}-${row.vehiclePlateColor}`
        this.componentName = 'IllegalCreate'
      } else {
        // 直接打开治理编辑页面
        this.componentName = 'GovernEdit'
      }
    },
    // 子页面跳转会父页面
    changeComponentName (backComponentName) {
      this.componentName = backComponentName
      if (backComponentName == 'default') {
        this.getTotalData()
      }
    },
    // 重开罚单
    reSetGoverns (params) {
      this.targetGovernParams.step = 1
      this.targetGovernParams.beginTime = params.beginTime
      this.targetGovernParams.endTime = params.endTime
      this.targetGovernParams.vehicle = params.vehicle
      this.componentName = 'IllegalCreate'
    },
    goBack () {
      this.$emit('goBack', 'default')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-detail-page();
.notification-detail {
  position: relative;
  height: 100%;
  width: 100%;
  .flex-item {
    display: flex;
    .btn-span {
      margin: 0 20px;
    }
  }
  .notice-item {
    .notice-icon, .notice-info {
      color: #ff9900;
      margin-right: 5px;
    }
  }
  .detail-item {
    .item-table {
      .pagenation-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
      }
    }
  }
}
</style>