<template>
  <div class="notification common-page">
    <div v-if="componentName == 'default'">
      <header class="page-header">
        <div class="header-left">
          <div class="title-instruction"></div>
          <span class="header-title">通报概况</span>
        </div>
        <div class="header-right"></div>
      </header>
      <section class="page-content">
        <div class="search-wrapper">
          <div class="search-title">违章筛选</div>
          <Divider class="divider-wrapper" size="small" />
          <div class="search-content">
            <div class="search-item">
              <RadioText @changeRadio="governStatusChange" :radio="noticeGovernStatus" :defaultRadio="searchForm.governSchedule" title="治理进度:" labelWidth="72"></RadioText>
            </div>
            <div class="search-item">
              <Form :model="searchForm" :label-width="0" inline>
                <FormItem label="接收单位:" :label-width="72">
                  <AutoComplete v-model="searchForm.unitName" @on-focus="unitSearch" @on-search="unitSearch" @on-select="unitSelect" @on-clear="clearAutoComplete" :clearable="true" placeholder="请选择企业" style="width:200px">
                    <Option v-for="item in unitArray" :value="`${item.unitName}|${item.unitId}`" :key="item.unitId">{{ item.unitName }}</Option>
                  </AutoComplete>
                </FormItem>
                <FormItem label="月份筛选:" :label-width="72">
                  <DatePicker v-model="searchForm.month" type="month" placeholder="请选择月份" :clearable="false" style="width: 200px"></DatePicker>
                </FormItem>
                <FormItem>
                  <Button v-if="btnPrivilegeCode.notification.includes('read')" @click="getTotalData('search')" :loading="searchLoading" class="form-btn" type="primary">查询</Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <Spin size="large" fix v-if="searchLoading"></Spin>
          <Table :columns="tableColmuns" :data="tableData" size="small">
            <template slot-scope="{ row }" slot="routeHop">
              <span v-if="Number(row.needHandleNum) > 0" class="btn-span">{{ row.needHandleNum }}</span>
              <span v-else>{{ row.needHandleNum }}</span>
            </template>
            <template slot-scope="{ row }" slot="action">
              <span v-if="btnPrivilegeCode.notification.includes('Details')" @click="openDetail(row)" class="btn-span">查看</span>
            </template>
          </Table>
          <div class="pagenation-wrapper">
            <Page
              :current="paginationConfig.currentPage"
              :page-size="paginationConfig.pageSize"
              :total="paginationConfig.total"
              @on-change="currentPageChange"
              show-total
              show-elevator
              size="small"/>
          </div>
        </div>
      </section>
    </div>
    <div v-else-if="componentName == 'NotificationDetail'">
      <NotificationDetail :targetRow="clickRow" @goBack="changeComponentName"></NotificationDetail>
    </div>
  </div>
</template>
<script>
import {btnPrivilegeMixin, unitSearchMixin, paginationMixin, privilegeMixin, paramsMixin, pageChangeMixin, userInfoMixin} from '@/assets/js/mixin.js'
import {getTimeStrByType} from '@/assets/js/common.js'
import RadioText from '@/components/radio-text/index.vue'
import NotificationDetail from './notification_detail/index.vue'
import axios from 'axios'
export default {
  mixins: [btnPrivilegeMixin, unitSearchMixin, paginationMixin, privilegeMixin, paramsMixin, pageChangeMixin, userInfoMixin],
  name: 'notification',
  components: {
    RadioText,
    NotificationDetail
  },
  data () {
    return {
      searchForm: {
        unitName: '',
        unitId: '',
        time: '',
        governType: '全部',
        governSchedule: [0],
        filterVehicle: '',
        noticeId: '',
        month: new Date()
      },
      searchLoading: false,
      tableColmuns: [
        {type: 'index', title: '序号', width: 60, align: 'center'},
        {title: '所属通报', key: 'title', align: 'center'},
        {title: '接收单位', key: 'unitName', align: 'center'},
        {title: '通报周期', key: 'noticePeriod', align: 'center'},
        {title: '通报下发时间', key: 'createTime', align: 'center'},
        // {title: '截止处理时间', key: 'noticeDeadline', align: 'center'},
        // {title: '剩余治理时间(天)', key: 'governDay', align: 'center'},
        // {title: '剩余治理车辆(辆)', key: 'needHandleNum', slot: 'routeHop', align: 'center'},
        {title: '治理进度', key: 'governScheduleRe', align: 'center'},
        {title: '操作', slot: 'action', align: 'center'}
      ],
      tableData: [],
      clickRow: null
    }
  },
  created () {
    this.getCurrentWeek()
  },
  methods: {
    async getTotalData (type) {
      if (type == 'search') {
        this.paginationConfig.currentPage = 1
      }
      let _params = {
        appKey: this.userInfo.appKey,
        governSchedule: this.searchForm.governSchedule[0],
        month: Number(getTimeStrByType(this.searchForm.month, '5')),
        noticeId: this.searchForm.noticeId,
        pageIndex: this.paginationConfig.currentPage,
        pageSize: this.paginationConfig.pageSize,
        unitId: this.searchForm.unitId ? this.searchForm.unitId : 0
      }
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/notice/noticePage`,
          data: _params
        })
        this.searchLoading = false
        this.isSearched = true
        if (data.code === 200) {
          this.tableData = data.data.records
          this.paginationConfig.total = data.data.total
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取平台信息失败, 请稍后重试!')
        }
      }
    },
    // 查看通报详情
    openDetail (row) {
      this.clickRow = row
      this.componentName = 'NotificationDetail'
    },
    // 违章类型改变
    governStatusChange (target) {
      this.searchForm.governSchedule = target
    },
    // id状态改变
    idStatusChange (target) {
      this.idStatus = target
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-table-page();
.notification {
  .page-content {
    .search-wrapper {
      padding-left: 8px;
      .search-content {
        .search-item {
          margin: 10px 0;
          .form-btn {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>